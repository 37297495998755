<template>
  <div class="page-container">
    <div class="nav-box">
      <nav-view></nav-view>
    </div>
    <div class="statistics-box g-bg">
      <statistics-view></statistics-view>
    </div>
    <div class="backlog-box g-bg">
      <backlog-view></backlog-view>
    </div>
    <div class="manager-box ">
      <div
        class="g-bg"
        style="height: 310px;"
      >
        <manager-view></manager-view>
      </div>
      <div class="user-work g-bg">
        <user-view></user-view>
      </div>
    </div>
  </div>
</template>

<script>
import NavView from './components/nav'
import StatisticsView from './components/statistics'
import BacklogView from './components/backlog'
import ManagerView from './components/manager'
import UserView from './components/user'
export default {
  components: {
    NavView,
    StatisticsView,
    BacklogView,
    ManagerView,
    UserView,
  },
}
</script>

<style lang="scss" scoped>
.page-container {
  display: grid;
  grid-template-areas:
    'nav nav manager'
    'statistics backlog manager';
  grid-template-rows: 120px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  width: 100%;
  height: 100%;
  padding: 0 15px 15px 15px;

  .nav-box {
    grid-area: nav;
  }

  .statistics-box {
    position: relative;
    grid-area: statistics;
    overflow: hidden;
  }

  .backlog-box {
    position: relative;
    grid-area: backlog;
    overflow: hidden;
  }

  .manager-box {
    position: relative;
    display: flex;
    flex-direction: column;
    grid-area: manager;
    overflow: hidden;

    .user-work {
      flex: 1;
      min-height: 0;
      margin-top: 15px;
      overflow: hidden;
    }
  }
}
</style>
