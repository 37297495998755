<template>
  <b-section-scroll title="常用功能">
    <el-row :gutter="15">
      <el-col
        :span="12"
        v-for="item in list"
        :key="item.title"
      >
        <div
          class="item"
          @click="onNav(item)"
        >
          <el-avatar
            shape="square"
            :size="40"
            :src="item.icon"
          ></el-avatar>
          <div class="g-ml-10">
            <div class="title">{{ item.title }}</div>
            <div class="desc">{{ item.desc }}</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </b-section-scroll>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: '要点',
          desc: '工作总结、计划',
          icon: require('@/assets/icons/manager-attention.png'),
          path: '/manager/attention',
        },
        {
          title: '清单',
          desc: '部门近期工作',
          icon: require('@/assets/icons/manager-list.png'),
          path: '/manager/list',
        },
        {
          title: '流程',
          desc: '部门工作、业务流程',
          icon: require('@/assets/icons/manager-flow.png'),
          path: '/manager/flow',
        },
        {
          title: '制度',
          desc: '部门各项管理制度',
          icon: require('@/assets/icons/manager-system.png'),
          path: '/manager/system',
        },
        {
          title: '反馈',
          desc: '部门内部任务统计',
          icon: require('@/assets/icons/manager-feedback.png'),
          path: '/manager/feedback',
        },
        // {
        //   title: '日报',
        //   desc: '增强计划性',
        //   icon: require('@/assets/icons/manager-schedule.png'),
        //   // path: '/manager/schedule',
        //   path: 'report/list/index',
        // },
      ],
    }
  },
  methods: {
    onNav(row) {
      this.$router.push(row.path)
    },
  },
}
</script>
<style lang="scss" scoped>
.item {
  display: flex;
  align-items: center;
  padding: 15px 0 15px 25px;
  margin-bottom: 15px;
  background-color: rgba($color: #000, $alpha: 0.05);
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    background-color: rgba($color: #000, $alpha: 0.1);
  }

  .title {
    font-size: 14px;
    font-weight: 600;
    color: #333;
  }

  .desc {
    margin-top: 2px;
    font-size: 12px;
    color: #888;
  }
}
</style>
